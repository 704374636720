import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "left" ,color:"rgb(31,31,31)",fontSize:"large"}}>
            <p style={{fontSize:"larger",textAlign:"center"}}>
              I'm <span className="orange">Lucy Ling </span> (凌珑)
            </p>
            <p>
              🪪 MS Student at Tongji University. <b>HCI / Interaction design</b>.
            </p>
            <p>
              🎓 B.S. in CS at Soochow University. <b>GPA: 3.8/4, rank: top 5%</b>.
            </p>
            <p>
              🧸 Internship: <b>Microsoft</b> SDE, 2021 / 2022 -> <b>ByteDance</b> FE, 2023
            </p>
            <p>
              📨 Email me at: <b>lucyling0224@gmail.com</b>
            </p>
          </p>

          <p>
            <img alt="React" src="https://img.shields.io/badge/-React-45b8d8?style=flat-square&logo=react&logoColor=white" />
            <img alt="Webpack" src="https://img.shields.io/badge/-Webpack-8DD6F9?style=flat-square&logo=webpack&logoColor=white" />
            <img alt="github actions" src="https://img.shields.io/badge/-Github_Actions-2088FF?style=flat-square&logo=github-actions&logoColor=white" />
            <img alt="TypeScript" src="https://img.shields.io/badge/-TypeScript-007ACC?style=flat-square&logo=typescript&logoColor=white" />
            <img alt="Styled Components" src="https://img.shields.io/badge/-Styled_Components-db7092?style=flat-square&logo=styled-components&logoColor=white" />
            <img alt="npm" src="https://img.shields.io/badge/-NPM-CB3837?style=flat-square&logo=npm&logoColor=white" />
            <img alt="html5" src="https://img.shields.io/badge/-HTML5-E34F26?style=flat-square&logo=html5&logoColor=white" />
            <img alt="d3js" src="https://img.shields.io/badge/-D3.js-F9A03C?style=flat-square&logo=d3.js&logoColor=white" />
            <img alt="Prettier" src="https://img.shields.io/badge/-Prettier-F7B93E?style=flat-square&logo=prettier&logoColor=white" />
            <img alt="Nodejs" src="https://img.shields.io/badge/-Nodejs-43853d?style=flat-square&logo=Node.js&logoColor=white" />
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
